import React from 'react';
import Layout from '../Layout';
import MainCard from '../common/MainCard';

const Home = () => {
    return (
        <Layout>
            <MainCard />
        </Layout>
    )
}

export default React.memo(Home);
