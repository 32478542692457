import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import useAuth from "../../../Routes/useAuth";
import {
  logout,
  onLogoutClearStoreData,
  setToastMessage,
} from "../../../js/redux/actions";
import "./navbar.scss";
import ScamyodhaLogo from "../../../images/icons/scamyodha_app_icon.svg";
import UserProfileIcon from "../../../images/icons/user_default.svg";
import DownArrowIcon from "../../../images/icons/dropdown_down.svg";

const Header = ({ toggleDrawer, showDrawer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const {
    auth: { isAuthenticated },
    setAuthData,
    userData,
  } = useAuth();

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownVisible((prevState) => !prevState);
  };

  // Use useCallback to memoize the outside click handler
  const handleOutsideClick = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false);
    }
  }, []);

  // Add and remove event listener based on popup visibility
  useEffect(() => {
    if (isDropdownVisible) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isDropdownVisible, handleOutsideClick]);

  const handleLogout = () => {
    dispatch(logout()).then((resp) => {
      if (resp?.data?.success) {
        dispatch(
          setToastMessage({ status: "success", message: resp?.data?.message })
        );
        setIsDropdownVisible(false);
        dispatch(onLogoutClearStoreData());
        setAuthData();
        navigate(`/auth/login`);
      }
    });
  };

  return (
    <nav className="navbar-container">
      <div className="navbar-logo">
        <Link to="/" className="navbar-brand">
          <img
            src={ScamyodhaLogo}
            alt="Scam Yodha"
            className="navbar-logo-img"
          />
          <span className="navbar-title">SCAM YODHA</span>
        </Link>
      </div>

      {/* Hamburger Icon for Mobile */}
      <div
        className="navbar-hamburger w3-hide-large w3-hide-medium"
        onClick={toggleDrawer}
      >
        &#9776;
      </div>

      {/* Logout Popup for Large Screens */}
      {isAuthenticated && userData && (
        <div ref={dropdownRef} className="w3-hide-small">
          <div class="navbar-user-info" onClick={(e) => toggleDropdown(e)}>
            <img
              src={UserProfileIcon}
              alt="User"
              className="navbar-user-icon"
            />
            <div className="navbar-user-name">{userData.name}</div>
            <img
              src={DownArrowIcon}
              alt="User"
              className="navbar-down-icon"
            />
          </div>
          {isDropdownVisible && (
            <div className="navbar-user-details w3-card w3-round w3-padding">
              {/* <div className="navbar-user-name">{userData.name}</div> */}
              <div className="navbar-user-phone">
                {`${userData.countryCode} ${userData.phoneNo}`}
              </div>
              <button
                className="logout-btn w3-bar-item w3-button"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          )}
        </div>
      )}

      {/* Auth Buttons for Large Screens */}
      {!isAuthenticated && (
        <div className="navbar-auth-buttons w3-hide-small">
          <button
            className="navbar-login-btn"
            onClick={() => navigate(`/auth/login`)}
          >
            Login
          </button>
          <button
            className="navbar-register-btn"
            onClick={() => navigate(`/auth/register`)}
          >
            Register
          </button>
        </div>
      )}

      {/* Mobile Drawer */}
      <div className={`navbar-drawer ${showDrawer ? "open" : ""}`}>
        <div className="navbar-drawer-close" onClick={toggleDrawer}>
          &times;
        </div>
        {isAuthenticated && userData && (
          <div className="drawer-user-info">
            <img
              src={UserProfileIcon}
              alt="User"
              className="drawer-user-icon"
            />
            <div className="drawer-user-details">
              <div className="drawer-user-name">{userData.name}</div>
              <div className="drawer-user-phone">
                {`${userData.countryCode} ${userData.phoneNo}`}
              </div>
            </div>
          </div>
        )}
        <ul className="navbar-drawer-menu">
          {!isAuthenticated ? (
            <>
              <li className="navbar-drawer-item">
                <button
                  className="navbar-login-mob-btn"
                  onClick={() => navigate(`/auth/login`)}
                >
                  Login
                </button>
              </li>
              <li className="navbar-drawer-item">
                <button
                  className="navbar-register-mob-btn"
                  onClick={() => navigate(`/auth/register`)}
                >
                  Register
                </button>
              </li>
            </>
          ) : (
            <li className="navbar-drawer-item" onClick={handleLogout}>
              <button className="navbar-register-mob-btn">Logout </button>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Header;
