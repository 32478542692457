import React, { useState, useEffect, useRef } from 'react';
import DownCaretIcon from '../../../images/icons/dropdown_down.svg';
import UpCaretIcon from '../../../images/icons/dropdown_up_active.svg';
import './drop-down.scss';

export default function SearchableDropdown(props) {
  const {
    label,
    placeholder,
    options = [],
    customCls,
    labelCls,
    onChange,
    selectedValue,
    containerCls,
    optionCls,
    valueCls,
    disabled,
    height
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
      setSearchTerm('');
      setFilteredOptions(options);
    }
  };

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
    setSearchTerm(''); // Clear the search field
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    const filtered = options.filter(option =>
      option.value.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const handleAddNew = () => {
    const formattedKey = `[Article][New] ${searchTerm.trim().toLowerCase().replace(/\s+/g, '-')}`;
    const newOption = { key: formattedKey, value: searchTerm };
    onChange(newOption);
    setIsOpen(false);
    setSearchTerm('');
  };

  const renderOption = (option) => {
    const isSelected = option.key === selectedValue?.key;
    const className = `fab-dropdown-option ${isSelected ? 'selected' : ''} ${optionCls || ''}`;

    return (
      <div
        key={option.key}
        className={className}
        onClick={() => handleOptionClick(option)}
      >
        {option.value}
      </div>
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setSearchTerm('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {label && <div className={`fab-form-label ${labelCls || ''}`}>{label}</div>}
      <div
        ref={dropdownRef}
        className={`fab-dropdown-container ${isOpen ? 'fab-dropdown-focused' : ''} ${customCls || ''}`}
        style={{ height }}
        onClick={toggleDropdown}
      >
        <div
          className={`fab-dropdown-selection ${disabled ? 'fab-dropdown-disabled' : ''}`}
        >
          {!isOpen && selectedValue ? (
            <div className={`fab-dropdown-selected-value ${valueCls || ''}`}>
              {selectedValue.value}
            </div>
          ) : (
            <input
              type="text"
              className="fab-dropdown-search-input"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder={placeholder}
              onClick={(e) => {
                e.stopPropagation(); // Prevent click event from propagating to the parent
                if (!isOpen) setIsOpen(true); // Open dropdown if it's not already open
              }}
            />
          )}
          <img
            className="fab-dropdown-icon"
            src={isOpen ? UpCaretIcon : DownCaretIcon}
            alt="caret-icon"
          />
        </div>
        {isOpen && (
          <div className={`${containerCls || 'fab-dropdown-options-container'}`}>
            {filteredOptions.length > 0 ? (
              filteredOptions.map(renderOption)
            ) : (
              <div className="fab-dropdown-no-options">
                <div>No options found</div>
                {searchTerm && (
                  <div
                    className="fab-dropdown-add-new"
                    onClick={handleAddNew}
                  >
                    Add "{searchTerm}"
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
