import React from 'react';
import './text-input.scss';

export default function TextArea(props) {
    const {inputclass, value, onChange } = props;
    return (
        <div className="fab-input-container">
            <textarea
                {...props}
                className={`fab-input ${!value?'fab-input-placeholder':'fab-input-active'} ${inputclass || ''}`}
                value={value}
                onChange={e =>onChange(e.target.value)}
            />
        </div>
    )
}
