import React, { useState } from 'react';
import './App.scss';
import Routes from './Routes';
import Navbar from './components/common/Navbar';
import Footer from './components/common/Footer';
import ToastMessage from './components/common/ToastMessage';
import DisplayBlogPosts from './components/Blog/DisplayBlogPosts';
import LeaderBoard from './components/common/LeaderBoard';
// import FAQSection from './components/FAQSection';
// import HowItWorksSection from './components/HowItWorksSection';


function App() {
  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawer = () => {
    setShowDrawer((value) => !value);
  };

  return (
    <div className='fabcaller-app'>
      {/* <div className='app-container' id='app-container'> 
        <div id='app-main'></div>
      </div> */}
      <ToastMessage />
      <Navbar toggleDrawer={toggleDrawer} showDrawer={showDrawer} />
      {/* 30-40-30 Layout */}
      <Routes section='auth' />
      {/* <HowItWorksSection />
      <FAQSection /> */}
      <Footer />
    </div>
  );
}

export default App;
