import React, { useState, useEffect } from 'react';
import '../../App.scss';
import './displayblogposts.scss';
import { ApolloClient, InMemoryCache, gql, HttpLink } from '@apollo/client';
import Loader from '../common/Loader';
import BlogPostCard from '../common/BlogPostCard';

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    fetch,
    uri: `https://blog.scamyodha.com/graphql`,
  }),
});

function DisplayBlogPosts() {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchBlogPosts = async () => {
    try {
      setIsLoading(true);
      await client
        .query({
          query: gql`
            query getAllPosts {
              posts(where: { categoryName: "Scam Yodha" }) {
                nodes {
                  title
                  date
                  slug
                  postId
                  link
                  status
                  excerpt(format: RENDERED)
                  featuredImage {
                    node {
                      altText
                      sourceUrl
                    }
                  }
                  author {
                    node {
                      name
                      avatar {
                        url
                      }
                      description
                    }
                  }
                  categories {
                    nodes {
                      name
                      categoryId
                      id
                      link
                    }
                  }
                  tags {
                    nodes {
                      name
                      slug
                      link
                      id
                    }
                  }
                }
              }
            }
          `,
        })
        .then((resp) => {
          setIsLoading(false);
          if (resp?.data?.posts?.nodes?.length > 0) {
            setPosts(resp.data.posts.nodes);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log('Error while fetching blog posts', err);
        });
    } catch (ex) {
      console.log('Error', ex);
    }
  };

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  if (isLoading) {
    return <Loader parentNode="blog-list-container" />;
  }

  return (
    <div className="blog-list-container-wrapper">
      <div className="blog-list-container">
        {posts.length > 0 ? (
          posts.map((item, i) => <BlogPostCard index={i} key={item.slug} item={item} />)
        ) : (
          <div className="fab-no-data w3-center">No data available</div>
        )}
      </div>
    </div>
  );
}

export default DisplayBlogPosts;
