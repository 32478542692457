import React, { useEffect, useState } from 'react';
import './auth.scss';
import AuthCard from './AuthCard';
import { useLocation, useNavigate } from 'react-router-dom';
import Login from './Login';
import VerifyOtp from './VerifyOtp';
import Register from './Register';
import Forgot from './Forgot';
import Modal from '../common/Modal';

export default function AuthLayout() {
    const location = useLocation();
    const navigate = useNavigate();

    const initialModalConfig = {
        class: 'w3-show',
        show: true,
        type: null,
    };

    const [view, setView] = useState(null);
    const [modalConfig, setModalConfig] = useState(initialModalConfig);

    useEffect(() => {
        const currentPath = location?.pathname.split('/').pop();
        setView(currentPath);
        return () => setView(null);
    }, [location]);

    const handleCloseModal = () => {
        setModalConfig({
            class: 'w3-hide',
            show: false,
            type: null,
        });
        navigate('/');
    }

    const getViewContent = () => {
        switch (view) {
            case 'register':
                return <Register />;
            case 'login':
                return <Login />;
            case 'forgot-password':
                return <Forgot />;
            case 'reset-password':
                return <VerifyOtp type="reset-password" />;
            case 'verify-otp':
                return <VerifyOtp />;
            default:
                return null;
        }
    };

    return (
        <>
            {modalConfig.show && (
                <Modal
                    openClass={modalConfig.class}
                    hideOverLay={true}
                    showCloseBtn={true}
                    handleClose={handleCloseModal}
                    transparentBackground
                >
                    <AuthCard>{getViewContent()}</AuthCard>
                </Modal>
            )}
        </>
    );
}
