import React from 'react';
import { Outlet } from 'react-router-dom';
import Home from '../../Home';

const AppLayout = () => {
  return (
    <div className="w3-row">
      {/* Static Home component */}
      <div className="w3-col m4">
        <Home />
      </div>
      {/* Dynamic route content */}
      <div className="w3-col m8">
        <Outlet />
      </div>
    </div>
  );
};

export default AppLayout;
