import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSpammerDetail, postScamRejection, scamVerification, setToastMessage } from "../../js/redux/actions";
import "./scam.scss";
import { clearFormFieldErrors, getProofUrl, maskString } from "../../js/utils";
import downloadBtn from '../../images/icons/download_btn.svg';
import ArrowLeftBlueBtn from '../../images/icons/arrow_left_blue.svg';
import moment from 'moment';
import { axiosApiCall } from "../../js/redux/actions";
import ProgressBar from "../common/ProgressBar";
import Loader from "../common/Loader";
import Button from "../common/Button";
import Modal from "../common/Modal";
import TextInput from "../common/TextInput";
import { SELECT_TYPE_OPTIONS } from "../../js/constants";
import PhoneNumberInput from "../common/PhoneNumberInput";
import useAuth from "../../Routes/useAuth";
import ErrorText from "../common/ErrorText";

const ReportDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const spammerList = useSelector((state) => state?.spammerListByType?.data?.data);
  const { id } = useParams();
  const { userData } = useAuth();
  const [reportData, setReportData] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [reportType, setReportType] = useState({});
  let modalContent = null;
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const initialmodalConfig = {
    class: 'w3-hide',
    show: false,
    type: null,
  }
  const [triggerModal, setTriggerModal] = useState(initialmodalConfig);
  const [btnDisable, setBtnDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const count = spammerList?.length;
  const nextLink = spammerList?.reduce((agg, item, idx) => {
    if (agg) {
      return agg;
    }
    if (item.id == id && idx < spammerList.length - 1) {
      agg = {
        idx: idx + 1,
        id: spammerList[idx + 1].id
      }
    }
    return agg;
  }, null);

  useEffect(() => {
    dispatch(getSpammerDetail(id)).then((resp) => {
      const respData = resp && resp.data;
      if (respData?.success && respData?.data) {
        const data = respData.data;
        setReportData(data);
        setReportType(SELECT_TYPE_OPTIONS.find((item) => item.key === data.type))
      }
    });
  }, [id]);
  const videos =
    reportData?.reports?.filter((item) => item.proof.match(/\.(mp4|mkv)$/)) ||
    [];
  const audios =
    reportData?.reports?.filter((item) => item.proof.match(/\.(mp3|ma4)$/)) ||
    [];
  const images =
    reportData?.reports?.filter((item) =>
      item.proof.match(/\.(png|jpg|jpeg)$/)
    ) || [];
  const pdf =
    reportData?.reports?.filter((item) => item.proof.match(/\.(pdf)$/)) || [];

  const handleDownload = async url => {
    const fileName = url.split('/').pop();
    let progress = 0;
    setIsDownloading(true)
    await axiosApiCall({
      url,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress: progressEvent => {
        progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        setPercentage(progress)
      }
    }).then((res) => {
      const { data } = res;
      const fileUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      setIsDownloading(false);
      setPercentage(0);
    }).catch((err) => { })
  };

  const renderDownloadList = (fileType, filesArray) => {
    return (
      <>
        <div className="fab-report-detail-proof-type">{fileType}</div>
        {filesArray.map((item) => {
          return (
            <div className="fab-report-detail-proof-download-section" key={item.id}>
              <div className="fab-report-detail-file-name">
                {item.proof.split('/').pop()}
              </div>
              <img src={downloadBtn} alt="download-btn" className="fab-cursor-pointer" onClick={() => handleDownload(getProofUrl(item.proof))} />
            </div>
          );
        })}
      </>
    );
  };

  const handleChange = (key, value) => {
    setErrors(clearFormFieldErrors(key, errors));
    setFormData({
      ...formData,
      [key]: value
    });
  }

  const handleVerify = () => {
    if (!formData.name || /^\s*$/.test(formData.name)) {
      setErrors({
        ...errors,
        name: 'This field is required',
      });
      return;
    }
    setBtnDisable(true);
    setIsLoading(true);
    dispatch(scamVerification(formData)).then(resp => {
      setIsLoading(false);
      if (resp.data.success) {
        dispatch(
          setToastMessage({
            status: "success",
            message: resp?.data?.message,
          })
        );
        setTriggerModal(initialmodalConfig);
        document.location.reload();
      }
      else {
        setTimeout(() => {
          setBtnDisable(false);
        }, 3500);
        dispatch(
          setToastMessage({
            status: "error",
            message: 'Something went wrong!',
          })
        );
      }
    })
  }

  const handleReject = () => {
    if (!formData.reason || /^\s*$/.test(formData.reason)) {
      setErrors({
        ...errors,
        reason: 'This field is required',
      });
      return;
    }
    setBtnDisable(true);
    setIsLoading(true);
    dispatch(postScamRejection(formData)).then(resp => {
      setIsLoading(false);
      if (resp.data.success) {
        dispatch(
          setToastMessage({
            status: "success",
            message: resp?.data?.message,
          })
        );
        setTriggerModal(initialmodalConfig);
        document.location.reload();
      }
      else {
        setTimeout(() => {
          setBtnDisable(false);
        }, 3500);
        dispatch(
          setToastMessage({
            status: "error",
            message: 'Something went wrong!',
          })
        );
      }
    })
  }

  const triggerRejectOrVerifyModal = actionType => {
    let fData = {
      ...formData,
      encryptedNumber: reportData.encryptedNumber,
      uniqueId: reportData.uniqueId,
      id: reportData.id
    }
    if (actionType === 'VERIFY') {
      fData = {
        ...fData,
        name: ''
      }
    } else if (actionType === 'REJECT') {
      fData = {
        ...fData,
        reason: ''
      }
    }
    setFormData(fData)
    setTriggerModal({
      class: 'w3-show',
      type: actionType,
      show: true
    })
  }

  const handleClose = () => {
    setTriggerModal(initialmodalConfig);
    setFormData({});
    setErrors({});
  }

  const getModalContent = viewType => {
    let title = '';
    if (reportType === 'number') {
      title = `Phone number of Scammer`;
    }
    else if (reportType === 'upi') {
      title = `UPI id of Scammer`;
    }
    else if (reportType === 'sms') {
      title = `SMS/Email scam link`;
    }
    else if (reportType === 'website') {
      title = `Web URL of scammer`;
    }

    const btnText = viewType === 'VERIFY' ? 'Verify' : 'Reject';
    modalContent = (
      <div className="fab-spam-verify-container">
        <div className="fab-form-control">
          <div className="fab-form-label">{title}</div>
          {reportType === 'number' ? <PhoneNumberInput
            placeholder="01234 56789"
            value={formData?.encryptedNumber || ''}
            name="encryptedNumber"
            onChange={() => { }}
            disabled
          /> : <TextInput
            type='text'
            value={formData?.encryptedNumber || ''}
            name="encryptedNumber"
            disabled
          />}
        </div>
        {viewType === 'VERIFY' && <div className="fab-form-control" style={{ marginBottom: 20 }}>
          <div className="fab-form-label">Scammer Name</div>
          <TextInput
            type='text'
            placeholder='Scammer Name'
            value={formData?.name || ''}
            onChange={(val) => handleChange('name', val)}
            name="name"
          />
          <ErrorText error={errors.name} errorClass="w3-center" />
        </div>}
        {
          viewType === 'REJECT' && <div className="fab-form-control">
            <div className="fab-form-label">
              Reason for rejection
            </div>
            <div className="fab-textarea-container fab-input-container">
              <textarea
                className={`fab-input ${!formData?.reason ? 'fab-input-placeholder' : 'fab-input-active'}`}
                name="reason"
                placeholder="Reason for rejection"
                value={formData?.reason || ''}
                onChange={e => handleChange('reason', e.target.value)}
              />
            </div>
            <ErrorText error={errors.reason} errorClass="w3-center" />
          </div>
        }
        <div>
          <Button
            onClick={viewType === 'VERIFY' ? handleVerify : handleReject}
            buttontext={btnText}
            disabled={btnDisable}
            customclass={`primary-btn ${btnDisable ? 'fab-btn-disabled' : ''}`}
            style={{ width: '100%' }}
          />
        </div>
      </div>
    )
    return modalContent;
  }

  return (
    <>
      {isDownloading && <ProgressBar percent={percentage} />}
      {(isDownloading || isLoading) && <Loader parentNode="fab-report-detail-container" />}
      <div className="fab-report-detail-container" id="fab-report-detail-container">
        <div className="fab-report-detail-top-section">
          <div
            className="fab-cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <img src={ArrowLeftBlueBtn} alt="back-btn" style={{ marginRight: 12 }} />
          </div>
          <div className="fab-page-heading">
            Report Detail
            {nextLink && <Link className="w3-right w3-small" 
              to={`/reports/detail/${nextLink.id}`}>Next {nextLink.idx} of {count}</Link>}
          </div>
        </div>
        <div className="fab-report-detail-card">
          <div className="fab-report-detail-card-inner">
            <div className="w3-block">
              <div className="fab-report-detail-card-title">{reportData?.encryptedNumber}</div>
              {reportData?.json?.scammerName && <div className="fab-report-detail-card-sub-title">Name<span className="fab-report-detail-card-sub-desc">{reportData?.json?.scammerName}</span></div>}
              <div className="fab-report-detail-card-sub-title">Last reported at<span className="fab-report-detail-card-sub-desc">{reportData?.lastReportedAt && moment(reportData?.lastReportedAt).format('lll')}</span></div>
              <div className="fab-report-detail-card-sub-title">Report count<span className="fab-report-detail-card-sub-desc">{reportData?.reportCount}</span></div>
              {reportData?.reports?.length && <div className="fab-report-detail-card-sub-title">Reported By<span className="fab-report-detail-card-sub-desc">{reportData?.reports?.[0].reporter?.name}</span></div>}
              {reportData?.reports?.length && reportData?.status === 'REJECTED' && <div className="fab-report-detail-card-sub-title">Verified By<span className="fab-report-detail-card-sub-desc">{reportData?.reports?.[0].verifier?.name}</span></div>}
              <div className="fab-report-detail-card-sub-title flex-container">
                <div className={`fab-report-detail-card-status ${reportData?.status === "ACTIVE" ? "active" : ""}`}>
                  {reportData?.status}
                </div>
              </div>
            </div>
          </div>
          {userData && userData?.rank > 10 && reportData?.status === "PENDING" && <div className="flex-container align-items-center justify-content-space-between w3-margin-top">
            <Button
              onClick={() => triggerRejectOrVerifyModal('REJECT')}
              buttontext="Reject"
              customclass="secondary-btn fab-report-list-card-verify-btn w3-red"
            />
            <Button
              onClick={() => triggerRejectOrVerifyModal('VERIFY')}
              buttontext="Verify"
              customclass="secondary-btn fab-report-list-card-verify-btn"
            />
          </div>}
        </div>

        {reportData?.status === 'REJECTED' && reportData?.json?.rejectReason && (<div className="fab-report-detail-card">
          <div className="fab-report-detail-description-container">
            <div className="fab-report-detail-dc-title">
              Reason for reject
            </div>
            <div className="fab-report-detail-dc-text w3-margin-bottom">
              {reportData?.json?.rejectReason}
            </div>
          </div>
        </div>)}


        {reportData?.detail && reportData?.reports?.length > 0 && (<div className="fab-report-detail-card">
          <div className="fab-report-detail-description-container">
            <div className="fab-report-detail-dc-title">
              Description
            </div>
            {
              reportData?.reports?.map(rItem => (
                <div key={rItem.id} className="w3-margin-bottom flex flex-col">
                  <div className="flex-container align-items-center fab-report-detail-dc-reported-by">
                    <span className="fab-report-detail-dc-sub-title reported-by">Reported By</span>
                    <div>
                      {rItem?.reporter?.name}
                    </div>
                  </div>
                  <div className="fab-report-detail-dc-text flex-container">
                    {rItem.detail}
                  </div>
                </div>
              ))
            }
          </div>
        </div>)}



        {((images.length > 0) || (videos.length > 0) || (audios.length > 0) || (pdf.length > 0)) && (<div className="fab-report-detail-card">
          <div className="fab-report-detail-proof-title">
            Proof
          </div>

          {images.length > 0 && (
            <div className="fab-report-detail-proof-img-container">
              <div className="fab-report-detail-proof-type">
                Images
              </div>
              {images.map((item) => (
                <div key={item.id}>
                  <div>
                    <a href={getProofUrl(item.proof)} target="_blank">
                    <img
                      src={getProofUrl(item.proof)}
                      className="fab-report-detail-proof-img"
                      alt="proof-img"
                    /></a>
                  </div>
                  <div className="fab-report-detail-proof-download-section img-download">
                    <div
                      className="fab-report-detail-file-name"
                    >
                      {item.proof.split('/').pop()}
                    </div>
                    <img src={downloadBtn} alt="download-btn" className="fab-cursor-pointer" onClick={() => handleDownload(getProofUrl(item.proof))} />
                  </div>
                </div>
              ))}
            </div>
          )}
          {videos.length > 0 && renderDownloadList("Videos", videos)}
          {audios.length > 0 && renderDownloadList("Audios", audios)}
          {pdf.length > 0 && renderDownloadList("Pdf", pdf)}

        </div>)}

        {triggerModal.show && <Modal
          openClass={triggerModal.class}
          hideOverLay={true}
          showCloseBtn={true}
          handleClose={handleClose}
        >
          <div className="fab-page-heading fab-page-heading-custom-pad">{triggerModal.type === 'REJECT' ? 'Reject ' : 'Verify '}Report</div>
          {getModalContent(triggerModal.type)}
        </Modal>}

      </div>
    </>
  );
};

export default ReportDetail;
