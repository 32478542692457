/* eslint-disable import/no-anonymous-default-export */
export default {
    //Auth Api's
    isTokenValid: {
        path: '/api/v1/auth/is-token-valid',
    },
    currentUser: {
        path: '/api/v1/auth/user',
    },
    register: {
        path: '/api/v1/auth/register',
        method: 'POST',
    },
    login: {
        path: '/api/v1/auth/login',
        method: 'POST',
    },
    forgotPassword: {
        path: '/api/v1/auth/forgot-password',
        method: 'POST',
    },
    resetPassword: {
        path: '/api/v1/auth/reset-password',
        method: 'POST',
    },
    verifyUser: {
        path: '/api/v1/auth/verify-user',
        method: 'POST',
    },
    resendOtp: {
        path: '/api/v1/auth/resend-otp',
        method: 'POST',
    },
    logout: {
        path: '/api/v1/auth/logout',
    },
    usersCount: {
        path: '/api/v1/auth/users-count'
    },
    //Scam Reposrt Api's
    checkScamReport: {
        path: '/api/v1/scam-report/check-scam-report',
        method: 'POST'
    },

    checkScamReportSendOTP: {
        path: '/api/v1/auth/send-otp',
        method: 'POST'
    },

    reportScammer: {
        path: '/api/v1/scam-report/report-scammer',
        method: 'POST'
    },

    verifyOtp: {
        path: '/api/v1/auth/verify-otp',
        method: 'POST'
    },
    myReports: {
        path: '/api/v1/scam-report/my-reports',
        method: 'GET'
    },
    spammerListByType: {
        path: '/api/v1/scam-report/spammer-list-by-type',
        method: 'GET'
    },
    spammerDetail: {
        path: '/api/v1/scam-report/spammer-detail',
        method: 'GET'
    },
    scamVerification: {
        path: '/api/v1/scam-report/scam-verification',
        method: 'POST'
    },
    scamRejection: {
        path: '/api/v1/scam-report/scam-rejection',
        method: 'POST'
    },
    seeUploadedFile: {
        path: '/api/v1/scam-report/file',
        method: 'GET'
    },
    reportsCount: {
        path: '/api/v1/scam-report/reports-count'
    },
    scamStats: {
        path: '/api/v1/scam-report/stats'
    },
    reporterLeaderBoard: {
        path: '/api/v1/scam-report/reporter-leader-board'
    },
    scamArticleTitles: {
        path: '/api/v1/scam-articles/titles'
    }
}