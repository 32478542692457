import React, { useMemo } from 'react';
import './profilepicture.scss';

const ProfilePicture = ({ firstName, size = 100 }) => {
  // Memoize the random blue color so it doesn't change on re-renders
  const randomBlue = useMemo(() => {
    return `hsl(${Math.floor(Math.random() * 41) + 200}, 70%, 70%)`;
  }, []); // Empty dependency array ensures the color is generated only once

  // Get the first letter of the first name
  const firstLetter = firstName.charAt(0).toUpperCase();

  return (
    <div
      className="profile-picture w3-margin-right"
      style={{
        backgroundColor: randomBlue,
        width: `${size}px`,
        height: `${size}px`,
        fontSize: `${size / 2}px`, // Adjust font size based on the size prop
      }}
    >
      {firstLetter}
    </div>
  );
};

export default ProfilePicture;
