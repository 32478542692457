import React, { useEffect, useState } from "react";
import { FAQS } from "../../js/constants";
import Accordion from "../common/Accordion";
import './faqsection.scss'

const FAQSection = () => {
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    return () => {
      setSelectedIndex(-1);
    };
  }, []);

  return (
    <div className="fab-faqs">
      <div className="fab-section-title">FAQ</div>
      <div className="fab-accordion-container">
        {FAQS.map((item, idx) => (
          <Accordion
            key={item.title}
            index={idx}
            title={item.title}
            content={item.content}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
