import React, { useEffect } from 'react';
import './modal.scss';

const Modal = (props) => {
    const {
        contentStyle,
        contentClass,
        children,
        modalClass,
        openClass,
        modalStyle,
        showCloseBtn,
        handleOverlayClick,
        handleClose,
        hideOverLay,
        header,
        transparentBackground,
    } = props;

    useEffect(() => {
        if (openClass === 'w3-show') {
            // Lock scroll when modal is open
            document.documentElement.classList.add('fab-no-scroll');
        } else {
            // Remove scroll lock when modal is closed
            document.documentElement.classList.remove('fab-no-scroll');
        }

        // Cleanup when modal is unmounted or openClass changes
        return () => {
            document.documentElement.classList.remove('fab-no-scroll');
        };
    }, [openClass]);

    const handleOnClick = (e) => {
        e.preventDefault();
        handleOverlayClick && handleOverlayClick();
    };

    const handleModalClose = (e) => {
        e.preventDefault();
        if (handleClose) {
            handleClose();
        } else {
            handleOnClick(e);
        }
    };

    return (
        <div
            className={`w3-modal ${openClass} ${modalClass || ''}`}
            style={{
                ...modalStyle,
            }}
        >
            {!hideOverLay && (
                <div
                    onClick={e => handleOnClick(e)}
                    className="fab-modal-overlay fab-cursor-pointer"
                ></div>
            )}
            <div
                className={`w3-modal-content ${contentClass || ''}`}
                style={{
                    ...contentStyle,
                    position: 'relative',
                    maxWidth: '600px',
                    backgroundColor: transparentBackground ? 'transparent' : '',
                }}
            >
                {showCloseBtn && (
                    <div
                        className="fab-modal-cross"
                        onClick={e => handleModalClose(e)}
                    />
                )}
                <div className="fab-modal-header-container">
                    {header && <div>{header}</div>}
                </div>
                {children}
            </div>
        </div>
    );
};

export default Modal;
