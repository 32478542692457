import React from 'react';
import './button.scss';

export default function Button(props) {
  const { onClick, customclass = '', buttontext, disabled = false } = props;

  return (
    <button
      {...props}
      className={`fab-btn ${customclass} ${disabled ? 'fab-btn-disabled' : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      {buttontext}
    </button>
  );
}
