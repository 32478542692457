import React from "react";
import "./blog-post.scss";
import moment from "moment";
import ProfilePicture from "../ProfilePicture"; // Import the ProfilePicture component

const BlogPostCard = ({ item, index }) => {
  if (!item) return null;

  const { title, excerpt, link, featuredImage, author, date, categories } =
    item;
  const { name: authorName, avatar } = author?.node || {};
  const featuredImgSrc = featuredImage?.node?.sourceUrl;
  const featuredImgAlt = featuredImage?.node?.altText;
  const category = categories?.nodes[0]?.name;

  // Fallback values
  const fallbackAuthorName = authorName || "Anonymous";
  const fallbackAvatarUrl = avatar?.url; // Placeholder image

  return (
    <div
      className={`w3-container w3-card w3-white w3-round w3-padding blog-main-container ${index === 0 ? "custom-margin-top" : "w3-margin"}`}
    >
      {/* Blog Post Link Section */}
      <a
        className="fab-nav-link"
        href={link.replace("scamyodha.blog.ceegees.in", "blog.scamyodha.com")}
        target="_blank"
        rel="noreferrer"
      >
        <div className="w3-row w3-margin-bottom">
          {/* Text Section */}
          <div className="w3-col m8 l9">
            <h5>{title}</h5>
            <div
              className="blog-description w3-margin-right"
              dangerouslySetInnerHTML={{ __html: excerpt }}
            />
          </div>

          {/* Featured Image Section */}
          <div className="w3-col m4 l3">
            <div className="featured-img-wrapper">
              <img
                src={featuredImgSrc}
                alt={featuredImgAlt}
                className="w3-image w3-round"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </a>

      {/* Author and Date Section */}
      <div className="flex-container align-items-center justify-content-start">
        {/* Conditionally render the avatar image or ProfilePicture */}
        {avatar?.url ? (
          <img
            src={fallbackAvatarUrl}
            alt="Author Avatar"
            className="w3-image w3-circle w3-margin-right"
            loading="lazy"
          />
        ) : (
          <ProfilePicture firstName={fallbackAuthorName} size={36} />
        )}

        <h6 className="w3-margin-right">{fallbackAuthorName}</h6>
        <span className="w3-opacity w3-small">{moment(date).format("ll")}</span>

        {/* Category Tag */}
        <div
          className="w3-tag w3-light-grey w3-round w3-padding-tiny"
          style={{ marginLeft: "auto" }}
        >
          {category}
        </div>
      </div>
    </div>
  );
};

export default BlogPostCard;
